<template>
  <div class="flex flex-col h-full" v-loading.fullscreen="loading">
    <div class="flex flex-row pb-8 border-b border-gray-400 border-solid header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader :title="$t('address_book.address_book')" @load:refreshList="fetchList" />
      </div>
      <div class="items-center flex flex-row header-container__right">
        <el-button type="primary" icon="el-icon-plus" size="medium" @click="onAddShippingAddress">
          {{ $t('address_book.new_shipping_address') }}
        </el-button>
        <div class="ml-8">
          <el-input
            prefix-icon="el-icon-search"
            size="medium"
            v-model="dataSearch.text"
            @keyup="refreshItemList"
            @blur="updateTextSearch"
          />
        </div>
      </div>
    </div>

    <!-- address book table -->
    <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
      <div class="my-16 flex-grow table-container">
        <el-table
          :data="displayItems"
          class="rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @current-change="onEdit"
        >
          <el-table-column prop="companyName" :label="$t('address_book.full_name')" width="250" />
          <el-table-column prop="country" :label="$t('address_book.country_region')" width="130">
            <template v-slot="scope">
              {{ convertToCountry(scope.row.country) }}
            </template>
          </el-table-column>
          <el-table-column prop="postalCode" :label="$t('address_book.postal_code')" width="130" />
          <el-table-column prop="address1" :label="$t('address_book.street_address')" />
          <el-table-column prop="phoneNumber" :label="$t('address_book.phone_number')" width="180" />
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="getItems.length"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        >
        </el-pagination>
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_ADDRESSES, SAVE_ADDRESSES } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import debounce from 'lodash/debounce'
import { IAddress, IAddressResponse, ICommonSearch } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Options({
  components: { PageHeader, ItemNotFound },
  name: 'AddressBook',
})
export default class AddressBook extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false

  get addressStore(): IAddressResponse {
    return this.$store.state?.address?.addresses || {}
  }

  get getItems(): IAddress[] {
    return this.addressStore?.addressBook || []
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems.filter(
      (addr: IAddress) =>
        this.cleanString(addr.companyName).includes(cleanSearchText) ||
        this.cleanString(this.$t(`countryList.${addr.country}`)).includes(cleanSearchText) ||
        this.cleanString(addr.postalCode).includes(cleanSearchText) ||
        this.cleanString(addr.address1).includes(cleanSearchText) ||
        this.cleanString(addr.phoneNumber).includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  convertToCountry(countryCode: string) {
    return countryCode ? this.$t(`countryList.${countryCode}`) : ''
  }

  onAddShippingAddress() {
    this.$router.push({
      name: 'address-book-detail',
      params: {
        action: 'add',
      },
    })
  }

  onEdit(addressBook: AddressBook) {
    this.$router.push({
      name: 'address-book-detail',
      params: {
        action: 'edit',
        address: JSON.stringify(addressBook),
      },
    })
  }

  async fetchList() {
    this.loading = true
    await this.$store
      .dispatch(FETCH_ADDRESSES)
      .then((res: IAddressResponse) => {
        res.addressBook.forEach((addr, index) => {
          addr.index = index
        })

        this.$store.commit(SAVE_ADDRESSES, res)
      })
      .finally(() => (this.loading = false))

    this.filterItems()
  }

  async created() {
    this.routeName = 'address-book'
    this.getQueryData()

    if (!this.$store.state.address.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => this.filterItems(), FILTER_DEBOUNCE_TIME)
  }
}
</script>
