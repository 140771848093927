
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_ADDRESSES, SAVE_ADDRESSES } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import debounce from 'lodash/debounce'
import { IAddress, IAddressResponse, ICommonSearch } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Options({
  components: { PageHeader, ItemNotFound },
  name: 'AddressBook',
})
export default class AddressBook extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false

  get addressStore(): IAddressResponse {
    return this.$store.state?.address?.addresses || {}
  }

  get getItems(): IAddress[] {
    return this.addressStore?.addressBook || []
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems.filter(
      (addr: IAddress) =>
        this.cleanString(addr.companyName).includes(cleanSearchText) ||
        this.cleanString(this.$t(`countryList.${addr.country}`)).includes(cleanSearchText) ||
        this.cleanString(addr.postalCode).includes(cleanSearchText) ||
        this.cleanString(addr.address1).includes(cleanSearchText) ||
        this.cleanString(addr.phoneNumber).includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  convertToCountry(countryCode: string) {
    return countryCode ? this.$t(`countryList.${countryCode}`) : ''
  }

  onAddShippingAddress() {
    this.$router.push({
      name: 'address-book-detail',
      params: {
        action: 'add',
      },
    })
  }

  onEdit(addressBook: AddressBook) {
    this.$router.push({
      name: 'address-book-detail',
      params: {
        action: 'edit',
        address: JSON.stringify(addressBook),
      },
    })
  }

  async fetchList() {
    this.loading = true
    await this.$store
      .dispatch(FETCH_ADDRESSES)
      .then((res: IAddressResponse) => {
        res.addressBook.forEach((addr, index) => {
          addr.index = index
        })

        this.$store.commit(SAVE_ADDRESSES, res)
      })
      .finally(() => (this.loading = false))

    this.filterItems()
  }

  async created() {
    this.routeName = 'address-book'
    this.getQueryData()

    if (!this.$store.state.address.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => this.filterItems(), FILTER_DEBOUNCE_TIME)
  }
}
